@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body{
	line-height: 1.5;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	overflow-x: hidden;
}
*{
	margin:0;
	box-sizing: border-box;
}
.divcent{
	align-items: center;
	text-align: center;
}
.sidebarItems{
    /* padding: 0px 50px 0px 50px; */
}
.whatwedo{
	color: #0659A4	;
	font-size: 48px;
	font-weight: bold;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	line-height: 64px;
	line-height: 100%;
	margin-top: 50px;
	margin-bottom: 40px;
}
.imagelogo {
       
	margin-top: 9px;
		  height: 50px;
		 width: 200px;
		
		
	  }
.viewnon{
	display: block;
}
.parawhat{
	Font-size: 18px;
Line-height: 36px;
Line-height: 150%;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
margin-top: 20px;
	margin-bottom: 40px;
}
:before,:after{
	box-sizing: border-box;
}
.container1{
	/* max-width: 3200px; */
	margin:auto;
}
.biglogo{
	height: 40px !important;
	width: 40px !important;
}
.row{
	display: flex;
	flex-wrap: wrap;
}
.v-center{
	align-items: center;
}
.navbtn{
	height: 50px;
	width: 120px;
	background-color: #0659A4	;
	font-size: 14px;
	border: none;
	color: white;
	font-weight: 600;
	margin-right:10px ;
	border-radius: 7px;
	

}
.whatwedoBtn{
	height: 90px;
	width: 430px;
	border: 1px solid #EC9B00;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	
}
.whatwedoBtn:hover{
	height: 90px;
	width: 430px;
	border: 1px solid #EC9B00;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	background-color: rgba(236, 155, 0, 0.19);
	
}
.whatwedoBtn1{
	height: 90px;
	width: 430px;
	border: 1px solid #6471D7	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	
}
.whatwedoBtn1:hover{
	height: 90px;
	width: 430px;
	border: 1px solid #6471D7	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	background-color: rgba(108, 115, 203, 0.19);
	
}
.whatwedoBtn2{
	height: 90px;
	width: 430px;
	border: 1px solid #BF9752	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	
}
.whatwedoBtn2:hover{
	height: 90px;
	width: 430px;
	border: 1px solid #BF9752	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	background-color: #f5d9a9;
	
}
.whatwedoBtn3{
	height: 90px;
	width: 430px;
	border: 1px solid #6471D7	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	
}
.whatwedoBtn3:hover{
	height: 90px;
	width: 430px;
	border: 1px solid #6471D7	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	background-color: rgba(100, 113, 215, 0.19);
	
}
.whatwedoBtn4{
	height: 90px;
	width: 430px;
	border: 1px solid #20AF92	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	
}
.whatwedoBtn4:hover{
	height: 90px;
	width: 430px;
	border: 1px solid #20AF92	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	background-color: rgba(32, 175, 146, 0.19);
	
}
.whatwedoBtn5{
	height: 90px;
	width: 430px;
	border: 1px solid #91B3FA	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	
}
.whatwedoBtn5:hover{
	height: 90px;
	width: 430px;
	border: 1px solid #91B3FA	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	background-color: rgba(76, 124, 224, 0.19);
	
}
.whatwedoBtn6{
	height: 90px;
	width: 430px;
	border: 1px solid #C86485	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	
}
.whatwedoBtn6:hover{
	height: 90px;
	width: 430px;
	border: 1px solid #C86485	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	background-color:rgba(200, 100, 133, 0.19) ;
	
}
.whatwedoBtn7{
	height: 90px;
	width: 430px;
	border: 1px solid #FE776B	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	
}
.whatwedoBtn7:hover{
	height: 90px;
	width: 430px;
	border: 1px solid #FE776B	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	background-color: rgba(200, 100, 133, 0.19);
	
}
.whatwedoBtn8{
	height: 90px;
	width: 430px;
	border: 1px solid #58B5D9	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	
}
.whatwedoBtn8:hover{
	height: 90px;
	width: 430px;
	border: 1px solid #58B5D9	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	background-color: rgba(88, 181, 217, 0.19);
	
}
.whatwedoBtn9{
	height: 90px;
	width: 430px;
	border: 1px solid #39A5CE	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	
}
.whatwedoBtn9:hover{
	height: 90px;
	width: 430px;
	border: 1px solid #39A5CE	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 40px;
	background-color: rgba(39, 119, 150, 0.19);
	
}
.orange{
	color: black;
}
.orange:hover{
	color: #EC9B00	;
}
.overflow{
	overflow-y: scroll;
	height: 700px;
	width: 100%;
	
}
.overflow::-webkit-scrollbar {
	display: none;
  }
.logowhat{
	height: 50px;
	width: 50px;
	margin-right: 30px;
}
ul{
	list-style: none;
	margin:0;
	padding:0;
}
a{
	text-decoration: none;
}
/* header */
.header{
	display: block;
	width: 100%;
	position: relative;
	z-index: 99;
	padding:15px;
}
.header .item-left{
	flex:0 0 17%;
}
.header .logo a{
	font-size: 30px;
	color:#000000;
	font-weight: 700;
	text-decoration: none;
}
.header .item-center{
	flex:0 0 66%;
}
.header .item-right{
	flex:0 0 17%;
	display: flex;
	justify-content: flex-end;
}
.header .item-right a{ 
     text-decoration: none;
     font-size: 16px;
     color:#555555;
     display: inline-block;
     margin-left: 10px;
     transition: color 0.3s ease;
}
.header .menu > ul > li{
	display: inline-block;
	line-height: 50px;
	margin-left: 25px;
}
.header .menu > ul > li > a{
	font-size: 15px;
	font-weight: 500;
	color:#000000;
	position: relative;
	text-transform: capitalize;
	transition: color 0.3s ease;
}
.header .menu > ul > li .sub-menu{
	position: absolute;
	z-index: 500;
	background-color:#ffffff;
	box-shadow: -2px 2px 70px -25px rgba(0,0,0,0.3); 
	padding: 20px 30px;
	transition: all 0.5s ease;
	margin-top:25px;
	opacity:0;
	visibility: hidden;
}
@media(min-width: 992px){
.header .menu > ul > li.menu-item-has-children:hover .sub-menu{
	margin-top: 0;
	visibility: visible;
	opacity: 1;
}
}
.header .menu > ul > li .sub-menu > ul > li{
	line-height: 1;
}
.header .menu > ul > li .sub-menu > ul > li > a{
	display: inline-block;
	padding: 10px 0;
	font-size: 15px;
	color: #555555;
	transition: color 0.3s ease;
	text-decoration: none;
	text-transform: capitalize;
}
.header .menu > ul > li .single-column-menu{
	min-width: 280px;
	max-width: 350px;
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li{
   line-height: 1;
   display: block; 
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a {
	padding:10px 0;
	display: inline-block;
	font-size: 15px;
	color:#555555;
	transition: color 0.3s ease;
}
.sidebarItems{
    padding: 10px 50px 10px 50px;
}
.sidebarItems:active{
    padding: 10px 50px 10px 50px;
    background-color: #0659A4;
	color: white;
}
.hideOptions{
    display: none;
}
.Techhead{
    padding: 10px 30px 10px 30px;
    font-size:24px;
    font-weight: bold;
}
.HireDevCont{
    background-color:  #f5f5ef;
}
.header .menu > ul > li .sub-menu.mega-menu{ 
    left: 50%;
    transform: translateX(-50%);	
}

.header .menu > ul > li .sub-menu.mega-menu-column-4{
  max-width: 1100px;
  width: 100%; 	
  display: flex;
  flex-wrap: wrap;
  padding:20px 15px;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item{
  flex:0 0 25%;
  padding:0 15px;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item .title{
	font-size: 16px;
	color:#ea4636;
	font-weight: 500;
	line-height: 1;
	padding:10px 0;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item.text-center .title{
	text-align: center;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img{
	max-width: 100%;
	width: 100%;
	vertical-align: middle;
	margin-top: 10px;
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a:hover,
.header .menu > ul > li .sub-menu > ul > li > a:hover,
.header .item-right a:hover,
.header .menu > ul > li:hover > a{
	color:#0659A4;
}
/* banner section */
/* .banner-section{
  background-image: url('../img/banner.jpg');
  background-size: cover;
  background-position: center;
  height: 700px;
  width: 100%;
  display: block;
} */
.mobile-menu-head,
.mobile-menu-trigger{
	display: none;
}
.divHeader{
	text-align: left;
}

.divHeader > p{
	font-size: 18px;
	line-height: 30px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.uldiv{
	text-align: left;
	display: flex;
	font-size: 15px;
	align-items: center;
	margin-top: 18px;
	margin-bottom: 18px;
}

.ecommerceHeading{
	color: #FAA81A	;
	font-size: 48px !important; 
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	margin-bottom: 15px;
}
.MobileHeading{
	color: #926EF9	;
	font-size: 48px !important; 
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	margin-bottom: 15px;
}
.FrontHeading{
	color: #39A5CE	;
	font-size: 48px !important; 
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	margin-bottom: 15px;
}
.backendHeading{
	color: #C86485	;
	font-size: 48px !important; 
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	margin-bottom: 15px;
}
.erpHeading{
	color: #6471D7	;
	font-size: 48px !important; 
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	margin-bottom: 15px;
}
.gred{
	
}
.MobileNavigation {
	display: none;
}
.webgrad{
	
		background-image: linear-gradient(to right, rgba(236, 155, 0, 0.19), rgb(255, 255, 255));
	 
}
.overflow1{
	overflow-x: scroll;
}
.appgrad{
	
	background-image: linear-gradient(to right, rgba(108, 115, 203, 0.19), rgb(255, 255, 255));
 
}
.entergrad{
	
	background-image: linear-gradient(to right, rgba(191, 151, 82, 0.19), rgb(255, 255, 255));
 
}
.softwaregrad{
	
	background-image: linear-gradient(to right, rgba(100, 113, 215, 0.19), rgb(255, 255, 255));
 
}
.contantgrad{
	
	background-image: linear-gradient(to right, rgba(32, 175, 146, 0.19), rgb(255, 255, 255));
 
}
.creativegrad{
	
	background-image: linear-gradient(to right, rgba(76, 124, 224, 0.19), rgb(255, 255, 255));
 
}
.socialgrad{
	
	background-image: linear-gradient(to right, rgba(200, 100, 133, 0.19), rgb(255, 255, 255));
 
}
.autograd{
	
	background-image: linear-gradient(to right,rgba(200, 100, 133, 0.19), rgb(255, 255, 255));
 
}
.aigrad{
	
	background-image: linear-gradient(to right,rgba(88, 181, 217, 0.19), rgb(255, 255, 255));
 
}
.colgrad{
	
	background-image: linear-gradient(to right,rgba(39, 119, 150, 0.19), rgb(255, 255, 255));
 
}
.navimg{
	margin-top: 90px;
	height: 250px;
	width: 300px;
}
.circleicon{
	margin-right: 10px;
	height: 1px;
	width: 1px;
}
/*responsive*/
@media(max-width: 991px){

	.header .item-center{
		order:3;
		flex:0 0 100%;
	}
	.header .item-left,
	.header .item-right{
		flex:0 0 auto;
	}
	.v-center{
		justify-content: space-between;
	}
	.header .mobile-menu-trigger{
		display: flex;
		height: 30px;
		width: 30px;
		margin-left: 15px;
		cursor: pointer;
		align-items: center;
		justify-content: center;
	}
    
	
	.header .mobile-menu-trigger span{
		display: block;
		height: 2px;
		background-color: #333333;
		width: 24px;
		position: relative;
	}
	.header .mobile-menu-trigger span:before,
	.header .mobile-menu-trigger span:after{
		content: '';
		position: absolute;
		left:0;
		width: 100%;
		height: 100%;
		background-color: #333333;
	}
	.hoveroption:hover{
        color: #0659A4;
	}
	.header .mobile-menu-trigger span:before{
		top:-6px;
	}
	.header .mobile-menu-trigger span:after{
		top:6px;
	}
	.header .item-right{
		align-items: center;
	}

	.header .menu{
		position: fixed;
		width: 320px;
		background-color:#ffffff;
		left:0;
		top:0;
		height: 100%;
		overflow: hidden;
		transform: translate(-100%);
		transition: all 0.5s ease;
		z-index: 1099;
	}
	.header .menu.active{
	   transform: translate(0%);	
	}
	.header .menu > ul > li{
		line-height: 1;
		margin:0;
		display: block;
	}
	.header .menu > ul > li > a{
		line-height: 50px;
		height: 50px;
		padding:0 50px 0 15px;
		display: block;
		border-bottom: 1px solid rgba(0,0,0,0.1);
	}
	
	.header .menu > ul > li > a i{
		position: absolute;
		height: 50px;
		width: 50px;
		top:0;
		right: 0;
		text-align: center;
		line-height: 50px;
		transform: rotate(-90deg);
	}
	.header .menu .mobile-menu-head{
		display: flex;
		height: 50px;
		border-bottom: 1px solid rgba(0,0,0,0.1);
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 501;
		position: sticky;
		background-color: #ffffff;
		top:0;
	}
	.header .menu .mobile-menu-head .go-back{
		height: 50px;
		width: 50px;
		border-right: 1px solid rgba(0,0,0,0.1);
		cursor: pointer;
		line-height: 50px;
		text-align: center;
		color:#000000;
		font-size: 16px;
		display: none;
	}
	.header .menu .mobile-menu-head.active .go-back{
		display: block;
	}
	.header .menu .mobile-menu-head .current-menu-title{
		font-size: 15px;
		font-weight: 500;
		color:#000000;
	}
	.header .menu .mobile-menu-head .mobile-menu-close{
	    height: 50px;
		width: 50px;
		border-left: 1px solid rgba(0,0,0,0.1);
		cursor: pointer;
		line-height: 50px;
		text-align: center;
		color:#000000;	
		font-size: 25px;
	}
	.header .menu .menu-main{
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
	}
	.header .menu > ul > li .sub-menu.mega-menu,
	.header .menu > ul > li .sub-menu{
		visibility: visible;
		opacity: 1;
		position: absolute;
		box-shadow: none;
		margin:0;
		padding:px;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		padding-top: 65px;
		max-width: none;
		min-width: auto;
		display: none;
		transform: translateX(0%);
		overflow-y: auto;
	}
.header .menu > ul > li .sub-menu.active{
	display: block;
}
@keyframes slideLeft{
	0%{
		opacity:0;
		transform: translateX(100%);
	}
	100%{
	    opacity:1;
		transform: translateX(0%);	
	}
}
@keyframes slideRight{
	0%{
		opacity:1;
		transform: translateX(0%);
	}
	100%{
	    opacity:0;
		transform: translateX(100%);	
	}
}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img{
		margin-top:0;
	}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item.text-center .title{
		margin-bottom: 20px;
	}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item.text-center:last-child .title{
		margin-bottom:0px;
	}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item{
		flex: 0 0 100%;
        padding: 0px;
	}
	.header .menu > ul > li .sub-menu > ul > li > a,
	.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a{
		display: block;
	}
	.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul {
		margin-bottom: 15px;
	}
	.menu-overlay{
		position: fixed;
		background-color: rgba(0,0,0,0.5);
		left:0;
		top:0;
		width: 100%;
		height: 100%;
		z-index: 1098;
		visibility: hidden;
		opacity:0;
		transition: all 0.5s ease;
	}
	.menu-overlay.active{
	  visibility: visible;
	  opacity:1;	
	}
}
@media only screen and (max-width: 1023px) {
	.viewnon{
	  display: block;
	}
  }
 


@media screen and (max-width: 1023px) {
   .MobileNavigation {
       
        display: block;
        
       
    }
	 .navbtn {
       
      margin: 0px;
        height: 30px;
       width: 80px;
	   font-size: 10px;
	   margin-right: -10px;
    }
	.imagelogo {
       
	margin-top: 9px;
		  height: 40px;
		 width: 270px;
		
		
	  }
}
@media screen and (max-width: 1278px) {
	.iconnone{
		
		margin-left: -27px;
		 
		
	 }
	

 }
 @media(max-width: 420px){
	.overflow {
		
			overflow-y: scroll;
			height: 700px;
			width: 100%;
		
	}
	.whatwedoBtn{
		width: 100%;
	}
 .colbtn
 {
	width: 800px;
 }
 .whatwedoBtn{
	height: 90px;
	width: 310px;
	border: 1px solid #EC9B00;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	
}

.whatwedoBtn1{
	height: 90px;
	width: 310px;
	border: 1px solid #6471D7	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	
}

.whatwedoBtn2{
	height: 90px;
	width: 310px;
	border: 1px solid #BF9752	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	
}

.whatwedoBtn3{
	height: 90px;
	width: 310px;
	border: 1px solid #6471D7	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	
}

.whatwedoBtn4{
	height: 90px;
	width: 310px;
	border: 1px solid #20AF92	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	
}

.whatwedoBtn5{
	height: 90px;
	width: 310px;
	border: 1px solid #91B3FA	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	
}

.whatwedoBtn6{
	height: 90px;
	width: 310px;
	border: 1px solid #C86485	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	
}

.whatwedoBtn7{
	height: 90px;
	width: 310px;
	border: 1px solid #FE776B	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	
}

.whatwedoBtn8{
	height: 90px;
	width: 310px;
	border: 1px solid #58B5D9	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	
}

.whatwedoBtn9{
	height: 90px;
	width: 310px;
	border: 1px solid #39A5CE	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	
}
.logowhat{
	margin: 4px;
}
.mobileres{
	text-align: left;
}.whatwedoBtn:hover{
	height: 90px;
	width: 310px;
	border: 1px solid #EC9B00;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	background-color: rgba(236, 155, 0, 0.19);
	
}

.whatwedoBtn1:hover{
	height: 90px;
	width: 310px;
	border: 1px solid #6471D7	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	background-color: rgba(108, 115, 203, 0.19);
	
}

.whatwedoBtn2:hover{
	height: 90px;
	width: 310px;
	border: 1px solid #BF9752	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	background-color: #f5d9a9;
	
}

.whatwedoBtn3:hover{
	height: 90px;
	width: 310px;
	border: 1px solid #6471D7	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	background-color: rgba(100, 113, 215, 0.19);
	
}

.whatwedoBtn4:hover{
	height: 90px;
	width: 310px;
	border: 1px solid #20AF92	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	background-color: rgba(32, 175, 146, 0.19);
	
}

.whatwedoBtn5:hover{
	height: 90px;
	width: 310px;
	border: 1px solid #91B3FA	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	background-color: rgba(76, 124, 224, 0.19);
	
}

.whatwedoBtn6:hover{
	height: 90px;
	width: 310px;
	border: 1px solid #C86485	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	background-color:rgba(200, 100, 133, 0.19) ;
	
}

.whatwedoBtn7:hover{
	height: 90px;
	width: 310px;
	border: 1px solid #FE776B	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	background-color: rgba(200, 100, 133, 0.19);
	
}

.whatwedoBtn8:hover{
	height: 90px;
	width: 310px;
	border: 1px solid #58B5D9	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	background-color: rgba(88, 181, 217, 0.19);
	
}

.whatwedoBtn9:hover{
	height: 90px;
	width: 310px;
	border: 1px solid #39A5CE	;
	margin: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 10px;
	background-color: rgba(39, 119, 150, 0.19);
	
}
 }
