.hirecontainer{
    background-color:  #07135F;
     padding: 40px 140px 60px 140px;
     margin-bottom: 60px;
}
.rowheading{
 color: white;
 font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 font-size: 48px;
 font-weight: bold;
 
}
.ellipescircle{
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background-color: white;
    justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.textcent{
    display: flex;
    align-items: center;
    justify-content: center;
}
.cent{
    display: block;
    justify-content: center;
    align-items: center;
    padding-left:10px ;
}
.arrow{
    height: 30px;
    width: 60px;
    margin-top: 55px;
    
}
.pad{
    margin-top: 37px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.pad2{
    margin-bottom: 60px;
    margin-top: 20px;
}
.arroww{
    height: 20px;
    width: 40px;

}
@media only screen and (max-width: 1250px) {
    .hiddenarrow{
      display: none;
    }
    .pad{
        margin-bottom: 20px;
    }
    .ellipescircle{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: white;
        justify-content: center;
      display: flex;
      align-items: center;
      justify-content: center;
      
    }
    .pad2{
        margin-left: -35px;
        margin-right: -35px;
    }
  }