.banner_Solution1 {

  width: 100%;
  background-image: url('../Assets/sol1_p.png');
  display: flex;
  background-repeat: no-repeat;
  background-size: contain;


}
.banner_Solution2 {
 
  width: 100%;
  background-image: url('../Assets/sol2_banner.png');
  display: flex;
  background-repeat: no-repeat;
  background-size: contain;


}
.banner_Solution3 {
  
  width: 100%;
  background-image: url('../Assets/bannersol-3.png');
  display: flex;
  background-repeat: no-repeat;
  background-size: contain;


}
.banner_Solution4 {
 
  width: 100%;
  background-image: url('../Assets/bannersol-4.png');
  display: flex;
  background-repeat: no-repeat;
  background-size: contain;


}
.banner_Solution5 {
 
  width: 100%;
  background-image: url('../Assets/bannersol-5.png');
  display: flex;
  background-repeat: no-repeat;
  background-size: contain;


}
.banner_Solution6 {
 
  width: 100%;
  background-image: url('../Assets/bannersol-6.png');
  display: flex;
  background-repeat: no-repeat;
  background-size: contain;


}
.Solutionbanner1_section {
  width: 50%;
  height: 50%;
  /* background-color: aquamarine; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: #ffffff;
margin: 7% 7%;
}
.secoundlast_solution {
  background-color: #09197a;
  height: 150vh;
  width: 80%;
}
.section3_solution {
  height: 75vh;
  width: 100%;
  background-image: url("../Assets/Frame\ 287.png");
  margin-top: 350px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}
.card2_sol1_sub {
  /* background-color: #3D4DAE; */
  height: 80%;
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.Solutionbanner2_section {
  width: 40%;
  height: 0%;
  /* background-color: red; */
}
.imgsizing_sol1 {
  height: 100%;
  width: auto;
}
.section1_solution {
  height: 50vh;
  width: 80%;
  /* background-color: red; */
  display: flex;
}
.section2_solution {
  height: 100vh;
  width: 80%;
  /* background-color: aqua; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.card_container_solu1 {
  /* background-color: gray; */
  height: 90%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.sub_card_sol1 {
  height: 27%;
  width: 30%;
  /* background-color: antiquewhite; */
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
 
  border-radius: 5px;
  background-color: #ffffff;
}
.sub_cards_sol1 {
  height: 35%;
  width: 26%;
  padding: 5% 5%;
  /* background-color: antiquewhite; */
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
 
  border-radius: 5px;
  background-color: #ffffff;
}
.sub_card_sol1:hover {
  height: 27%;
  width: 30%;
  /* background-color: antiquewhite; */
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  background-color: #ffffff;
}
.sub_cards_sol1:hover {
  

  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.18);
 
}
.bottom_card_dol
{
 height: auto;
 width: auto;
 /* background-color: #09197A; */
 display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 50px;
  flex-wrap: wrap;


}

.sub_card_sol2 {
  height: 45%;
  width: 30%;
  /* background-color: antiquewhite; */
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  background-color: #ffffff;
}

.section1_content {
  width: 70%;
  height: 100%;
  align-content: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section2_img {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_body_soltions {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: black; */
}

.imgsizing_sol2 {
  width: 90%;
}
.btn_sol1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_sub_col1 {
  width: 200px;
  background-color: #3d4dae;
  box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #ffffff;
  height: 40px;
  border: none;
  outline: none;
}

@media screen and (max-width: 480px) {
  .banner_Solution1 {
    height: 110vh;
    width: auto;
    background: linear-gradient(90deg, #09197A 34.42%, #3D4DAE 100%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }

  .Solutionbanner1_section {
    width: 70%;
    height: 50%;
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: #ffffff;
  }
  .Solutionbanner2_section {
    width: 80%;
    height: 50%;
    /* background-color: red; */
  }
  .imgsizing_sol1 {
    height: 100%;
    width: auto;
  }

  .section1_solution {
    height: 100vh;
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .section1_content {
    width: 100%;
    height: 70%;

    align-content: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .section3_solution {
    display: none;
  }
  .section2_img {
    width: 100%;
    height: 70%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section2_solution {
    height: 350vh;
    width: 80%;
    /* background-color: aqua; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }

  .card_container_solu1 {
    /* background-color: gray; */
    height: 90%;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  .sub_card_sol1 {
    height: 27%;
    width: 90%;
    /* background-color: antiquewhite; */
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.18);
    border-radius: 5px;
    background-color: #ffffff;
  }
  .sub_cards_sol1 {
    height: 27%;
    width: 90%;
    /* background-color: antiquewhite; */
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.18);
    border-radius: 5px;
    background-color: #ffffff;
  }
}

.text-askari-primary {
  color: #3d4dae;
}

.client_connect_app_card {
  margin: 5px;
  height: 280px !important;
  border: none !important;
}

.client_connect_app_card:hover {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
}

.empowering_business {
  background-image: url("../Assets/images/client_keyboard_app.png");
  padding: 30px;
  height: 40vh;
  background-repeat: no-repeat;
  margin-top: -50px;
}
