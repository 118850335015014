@import url('https://fonts.cdnfonts.com/css/segoe-ui-4');
.ourventure_baner {
  height: 27vh;
  width: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #0659A4;
  background-image: url("../Assets/Banner\ Background.png");
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  box-sizing: border-box;
}
.Venture_heading {
  color: white;
  margin-left: 10vw;
  font-weight: bolder;
  font-size: 40px;
}
.Main_container_venture {
  padding: 0% 10%;
  /* background-color: rebeccapurple; */
  /* background-color: #d9d9d9; */
}
.line_color
{
  background-color: #0659A4;
  height: 5px;
  width: 100px;
  margin-bottom: 10px;
}
.Secound_venture {
  height: 55vh;
  width: 100%;
   background-color: #d9d9d97a;;
   padding: 0% 10%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}
.five_venture {
  height: 60vh;
  width: 100%;
  /* background-color: aquamarine; */
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 0% 10%;
  background-color: white;
}
.six_venture{
  height: 60vh;
  width: 100%;
  padding: 0% 10%;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}
.four_venture {
  height: 60vh;
  width: 100%;
  padding: 0% 10%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}
.third_venture {
  height: 70vh;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
   padding: 0% 10%;
}
.text_card_venture {
  font-size: 11px;

  display: flex;
  text-align: center;
  padding: 2%;
}
.head3
{
  color: #0659A4;
  font-weight: bolder;
  font-size: 35px;
}
.cards_venture {
  height: 50vh;
  width: 100%;
  padding: 2px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}

.subcards_venture {
  height: 90%;
  width: 20%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.main_crad_parent {
  width: 100%;
  height: 35vh;
  display: flex;
  justify-content: space-evenly;
}
.heading_sub_venture {
  color: #0659a4;
  font-family: 'Segoe UI', sans-serif;

}
.img_text_venture {
  width: 60%;
  height: 100%;
  color: #686868;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.img_venture {
  /* background-image: url('../Assets/land-rev-1-img-2\ 1.png');
    background-repeat: no-repeat;
    background-size:cover; */
  width: 55%;
  height: 111%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20%;
}
.txt_venture_sub {
  color: #686868;
  font-size: 14px;
  
}

.sizing {
  height: 80%;
  width: 58%;
}
.questionsizsing {
  height: 40%;
  width: 20%;
  margin-bottom: 40%;
  margin-right: -10%;
}
.sizing2 {
  height: 50%;
  width: 85%;
}
.sizing_mar_2
{
  height: 60%;
  width: 95%;
  margin-right: 100%;
}
.fixe_bg
{
 background-position: fixed !important; 
}
.blue_color
{
  color: #0659A4;
}
.small_size
{
  font-size: 12px;
}
.boxes_question
{
  margin-bottom: 5px;
}

@media screen and (max-width: 480px) {

  
  .Secound_venture {
    height: 90vh;
    width: 100%;
    /* background-color: green; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .five_venture {
    height: 90vh;
    width: 100%;
    /* background-color: green; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .six_venture {
    height: 150vh;
    width: 100%;
  
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .four_venture {
    height: 90vh;
    width: 100%;
    /* background-color: green; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .third_venture {
    height: 90vh;
    width: 100%;
    /* background-color: green; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 10vh;
  }
  .img_venture {
    /* background-image: url('../Assets/land-rev-1-img-2\ 1.png');
    background-repeat: no-repeat;
    background-size:cover; */
    width: 100%;
    height: 50%;
    margin-left:0% ;
  }
  .img_text_venture {
    width: 100%;
    height: 50%;
  }
  .subcards_venture {
    height: 30%;
    width: 100%;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 10px;
    padding: 5px;
  }
  .main_crad_parent {
    width: 90%;
    height: 70vh;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .cards_venture {
    height: 120vh;
    width: 100%;
    padding: 2px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    margin-top: 30vh;
   
  }
  .sizing_mar_2
{
  height: 60%;
  width: 95%;
  margin-right: 0px;
  
}
.card_img_sizing
{
  height: 30px;
}

}
