.ourpatners_baner {
  height: 25vh;
  width: auto;
  background-color: #0659A4;
  background-image: url("../Assets/Banner\ Background.png");
  display: flex;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.patner_heading {
  color: white;
  margin-left: 10vw;
}
.Ourpatners_main {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(235, 235, 235);
}
.Ourpatners_firstchild {
  height: 80vh;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}
.Ourpatners_child {
  height: 100%;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: aqua; */
  margin-left: 5%;
}

.Ourpatners_secoundchild {
  height: 80vh;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Ourpatners_child2 {
  height: 105%;
  width: 30%;
  padding-bottom: 10%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  align-items: center;
 
  background-color: #1f1e1e;
  border-radius: 22px;
  margin-top: -170px;
  color: white;
  padding: 5px;
  margin-left: 50px;
}
.input {
  background: #373435;
  border-radius: 5px;
  width: 80%;
  align-self: flex-start;
  margin-left: 9%;
  height: 35px;
  border: none;
  outline: none;
  padding-left: 10px;
  margin-top: 5px;
}
.input4 {
  background: #373435;
  border-radius: 5px;
  width: 80%;
  align-self: flex-start;
  margin-left: 9%;
  height: 70px;
  border: none;
  outline: none;
  padding-left: 10px;
  margin-top: 5px;
  
}
.input2 {
  background-color: white;
  border-radius: 5px;
  width: 40%;
  align-self: flex-start;
  margin-left: 2%;
  border: none;
  outline: none;
  height: 40px;
  margin-left: 9%;
  margin-top: 10px;
}
.heading_oupatners {
  color: #0659a4;
}
.button_patners {
  background-color: #0659a4;
  border-radius: 5px;
  color: #ffffff;
  width: 160px;
  height: 35px;
  border: none;
  outline: none;
}
.patner_sizing {
  height: 60%;
  width: 80%;
}
.secound_section {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top:50px ;
}
.cards_ourpatners {
  height: 50vh;
  width: 100%;
  /* background-color: black; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.cards_ourpatners2 {
  height: 50vh;
  width: 100%;
  /* background-color: black; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 450px;
}
.card_op2 {
  height: 70%;
  width: 19%;
  background-color: #ffffff;
  padding: 1%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-top: 30px;
  margin-left: 20px;
}
.card_child {
  width: 30%;
  height: 65%;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-left: 50px;
}
.card_child_4 {
  width: 30%;
  height: 65%;

  border-radius: 8px;
  margin-left: 200px;
}
.card_child2 {
  width: 40%;
  height: 65%;
  background-color: #ffffff;
}
.first_card_child {
  /* background-color: #0659A4; */
  height: 30%;
  width: auto;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  
}
.img_sizing_ourpatner {
  height: 100%;
  width: 22%;
}
.content_ourpatner {
  width: 90%;
  display: flex;
  /* background-color: aqua; */
  margin-left: 5%;
  color: #344054;
  font-size: 11px;
  margin-top: 2px;
}
.banner_ourpatner {
  height: 50vh;
  width: 90%;
  /* background-image: url('../Assets/unsplash_kRNZiGKtz48\ 1.png');
    background-repeat: no-repeat;
    background-size: cover; */
  margin-left: 5%;
}
.font_sizing_op {
  font-size: 11px;
  text-align: center;
}
.font_sizing_op_heading {
  font-size: 18px;
  text-align: center;
}
.bg_map {
  background-image: url("../Assets/map.png");
  display: flex;
  height: 60vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: space-around;
 
}
@media screen and (max-width: 480px) {
 
  .Ourpatners_main {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
  }
  .Ourpatners_firstchild {
    height: 50vh;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }
  .Ourpatners_secoundchild {
    height: 40vh;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cards_ourpatners {
    height: 90vh;
    width: 100%;
    /* background-color: black; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .card_child {
    width: 90%;
    height: 35%;
    background-color: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
 
    margin-right: 50px;
  }
  .card_child2 {
    width: 90%;
    height: 35%;
    background-color: #ffffff;
  }
  .cards_ourpatners2 {
    height: 300vh;
    width: 90%;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

  }
  .card_op2 {
    height: 10%;
    width: 80%;
    background-color: #ffffff;
    padding: 1%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-top: 40px;
  }
  .bg_map {
    background-image: none;

  }
  .Ourpatners_child2 {
    height: 100%;
    width: 100%;
    padding: 2% 7%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    align-items: center;
    background-color: #1f1e1e;
    border-radius: 22px;
    margin-top: 0px;
    color: white;
    padding: 5px;
    margin-top: 0px;
    margin-right: 30px;
  }
  .Ourpatners_child {
    display: none;
  }
  .ganjaimg
  {
    background-color: #0659A4;
    width: 100% !important;
    
  }
  .card_child_4 {
    width: 90%;
    height: 40%;
  
    border-radius: 8px;
    margin-left: 0px;
  }

}
