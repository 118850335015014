@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,1000;1,200;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,600&family=Poppins:wght@500&family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400&family=Sora&family=Titillium+Web:ital,wght@0,200;0,300;1,300&family=Work+Sans:wght@500;600;700;800&display=swap");
/* Google Fonts - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif; 
  
}

 
.bannner-image {
  width: 100%;
  max-width: 100%;
  height: 85vh;
  display: flex;
  color: white;
  padding-left: 15%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../Assets/ecomer-banner.png");
  padding-top: 5%;
}
.btn_pro
{
  background-color: #040108;
  border: none;
  outline: none;
  background-color: white;
  width: 190px;
  height: 40px;
  border-radius: 5px;
  color: #015364;
  font-size: 14px;
  padding: 10px;
  margin-top: 5%;
  
}
.MainHeading {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 4vw;
  font-weight: 400;
}

.headPara {
  margin-top: 0;
}

.bannner-image p {
  position: relative;
  top: 7%;
}

.text-content {
  margin-top: 7%;
  margin-left: 10%;
  line-height: 2;
}

/* Adjust font sizes and positioning for different screen sizes */


.image-container {
  position: relative;
  display: inline-block;
 
}

.overlay-image {
  
  top: 0;
  left: 0;
  height: 70%;
  padding-left: 30%;
  margin-top: 5%;
}
.overlay-image2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 70%;
  padding-left: 30%;
  margin-top: 5%;
}
.para_pro_txt
{
  font-size: 20px;
  color: #000000;
}
.bg-image {
  width: 50vw;
  height: auto;
  margin-left: -40px;
}

.servicespara {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-top: 10vh;
}

.mainsection {

  width: 100vw;
  display: flex;
 
}




.align_product
{
  display:"flex";
  flex-direction:"column";
   text-align: center;
   padding-top: 50px;
  
}
.align_product
{
   text-align: center;
   padding-top: 50px;
  
}
.align_product h3
{
   text-align: center;
   padding-top: 50px;
   color: #3d4dae;
   font-weight: bolder;
   font-family: "Segoe UI";
   font-size: 35px;
}
.align_product p
{
   
   color: #686868;
   font-family: "Segoe UI";
}




.erpsoftware p {
  font-family: "Segoe UI";
  margin-top: -10px;
  font-size: 14px;
  text-align: center;
  color: #686868;
}

.image-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-item {
  width: 150px;
  height: px;
  margin: 10px;

}

.image-item img {
  width: 100%;
  height: 100%;
 
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  color: #fff;
  padding: 10px;
  text-align: left;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 25px;
  color: #ffffff;
}

.caption p {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 350;
  font-size: 14px;

  line-height: 19px;
}

.caption h4 {
  margin-bottom: 2%;
}

.main-section-1 {
  height: 100%;
  width: 100vw;
  background: linear-gradient(90deg, #09197a 34.42%, #3d4dae 100%);
  /* width: 100vw; */
}

.Main-ul {
  list-style: none;
}
.Main-ul a {
  list-style: none;
  text-decoration: none;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
}
.Brand-Name {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 2.5rem;
  color: #ffffff;
}
.Brand-slogen {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 350;
  font-size: 16px;

  color: #ffffff;
  padding-top: 1rem;
}
.Hire-btn {
  background: #ffffff;
  border-radius: 3px;

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;

  line-height: 27px;
}

/* Section 2 Css */

.Main-About-img-2 {
  width: 99%;
  height: 100%;
}
.about-head {
  color: rgba(64, 80, 175, 1);
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
}
.Brand-slogen {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  line-height: 133.01%;
}
.main-2 {
  background: #f8f9fc;
}

/* Contact section  */


.conact-c1 {
  background-image:url('../Assets/contactbg.png') ;
  background-repeat: no-repeat;
  background-size: contain;
  width: 50%;
  padding-top: 10%;
  padding-left: 25%;
 
}
.contact-head {
 

  font-size: 40px;
  text-align: left;
  font-weight: bolder;
  font-family: "Segoe UI";
  /* or 110% *
  /* base/white */
  color: #ffffff;
  letter-spacing: 0.5px;
 
  
}
.btn_get_form
{
  background-color: #3d4dae;
  color: white;
  border: none;
  outline: none;
  padding: 8px 20px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.input_pro_eng
{
  background-color: transparent;
  outline: none;
  border: 1px solid #CBD5E1 ;
  display: block;
  height: 40px;
  border-radius: 5px;
  width: 80%;
 padding-left: 10px;
 margin-top: 5px;
 font-size: 12px;
 }
 .input_pro_eng_inp
{
  background-color: transparent;
  outline: none;
  border: 1px solid #CBD5E1 ;
  display: block;
  height: 90px;
  border-radius: 5px;
  width: 80%;
 padding-left: 10px;
 margin-top: 5px;
 font-size: 12px;
 }
.contact-para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
 
  margin-top: 2%;
  /* or 167% */

  /* blue-gray/300 */

  color: #cbd5e1;
}
.form-label
{
  color: #000000;
  font-weight: bold;
}
.conatact-tittle {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  color: #3d4dae;
  font-size: 40px;
}
.conact-c2 {
  background: #f8f9fc;
  padding: 3% 2%;

}
.btn-started {
  background: #3d4dae;
  border-radius: 9px;
  padding: 16px;
  width: 188px;
  height: 55px;
  color: #ffffff;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;

  line-height: 25px;
  align-items: center;
  text-align: center;
  outline: none;
  border: none;
}
.guaranteed {
  color: #3d4dae;
  
}
.services {
  background: #f8f9fc;
}
.services-head {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;

  text-align: center;
  line-height: 64px;
  color: #4050af;
}
.services-para {
  text-align: start;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #686868;
  line-height: 37px;
}
.services-card {
  /* max-height: fit-content !important; */
  padding: 1rem;
  background: #fefefe;
  margin: 0.5rem;
  box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.25);
}
.car-heading {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;

  line-height: 26px;

  letter-spacing: -0.01em;

  color: #3d4dae;
}
.card-para {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 350;
  font-size: 13px;

  line-height: 18px;
  /* or 138% */

  color: #000000;
}
.services-row {
  align-items: center;
  justify-content: center;
  display: flex;
}

.arrival-card {
  background: #fefefe;
  text-align: center;
  max-width: fit-content;
  box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.25);
}
.Arrival-head {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;

  line-height: 32px;

  text-align: center;

  color: #3d4dae;
}
.icons-arrival {
  font-size: 3rem;

  color: rgba(61, 77, 174, 1);
}
.arrival-head {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  line-height: 26px;
  /* or 142% */

  text-align: center;
  letter-spacing: -0.01em;
}
.bg_baneer_proemg
{
  height: 80vh;
  width: 100vw;
  background-image: url('../Assets/bg_baneer_proemg.png');
  background-repeat: no-repeat;
  background-size:  contain;
}
.bg_baneer_proemg_erp
{
  height: 80vh;
  width: 100vw;
  background-image: url('../Assets/erp_banner_secound.png');
  background-repeat: no-repeat;
  background-size:  contain;
}
.your_patner_pro
{
  background-color: #4050AF;
  height: 40vh;
  width: 100%;
  margin-top: 100px;
  text-align: center;
  color: white;
  padding: 3% 10%;
   margin-bottom: 40vh;
}
.card_pro_eng_botm
{
   height: 40vh;
   justify-content: space-between;
   display: flex;
   flex-direction: row;
   margin-top:50px ;

}
.sub_card_pro_eng_head
{
  /* background-color: #015364; */
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-wrap: wrap;
  
}
.sub_card_pro_eng_botm
{
  height: 90%;
  width: 24%;
  background-color: white;
  border-radius: 4px;
  /* background-color: #000000; */
  margin-left: 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
.sub_card_pro_eng_footer
{
  /* background-color: yellow; */
  height: 80%;
  display: flex;
  text-align: left;
  flex-direction: column;
  font-size: 10px;
  color: #686868
  ;
}.sub_card_heading
{
  color:#4050AF;
  font-size: 16px;
 
  margin-top: 10px;
}
.arrival-head {
  font-size: 16px;
}
.hireDeveloper {
  background: #fefefe;
}
.para_pro_card_eng
{
  margin-top: 2%;
  margin-left: 5px;
}
.heading {
  color: #3d4dae;
  font-size: 50px !important;
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
}
.border_right_bottom
{
  border-bottom:1px solid  gray ;
  border-right:1px solid  gray ;
}
.border_bottom
{
  border-bottom:1px solid  gray ;
  
}
.border_right
{
  border-right:1px solid  gray ;
  
}
.subHead {
  color: #52525b;
  font-size: 14px;
  margin-top: 25px;
  font-family: "Plus Jakarta Sans", sans-serif;
  text-align: center;
}

.column {
  padding: 50px;
  text-align: center; /* Align content in the center */
}

.name {
  font-weight: bold;
  line-height: 28px;
  font-size: 21px;
  margin-top: 30px;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.enterprise {
  margin-top: 5%;
}
.enterprise h3 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 64px;
  color: #3d4dae;
}
.enterprise .para1 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 37px;
  margin-top: 4%;
  /* or 185% */

  text-align: center;
  letter-spacing: -0.01em;

  /* Text color 2 */

  color: #686868;
}
.steps p {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  text-align: center;

  /* Text color 2 */

  color: #686868;
}
.responsive-section {
  position: relative;
  width: 1349px; /* Use viewport width */
  height: 600px;
 
  margin-top: 2%;
}

.background-image {
  width: 100vw; /* Use viewport width */
  height: 100%;
  object-fit: cover;
  object-position: left;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
}

.content {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  color: #ffffff;
  z-index: 1;
  width: 100%;
  max-width: 600px; /* Adjust the width as per your needs */
  padding-left: 20px; /* Adjust the left margin as per your needs */
  box-sizing: border-box;
}

.heading {
  font-size: 24px;
  margin-bottom: 10px;
}

.paragraph {
  font-size: 18px;
  margin-bottom: 0;
}

.codheading {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: 46px;
}
.content .paragraph {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-top: 8%;
}

.feature {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.check-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.feature-text {
  margin: 0;
}
.checkicons {
  margin-top: 5%;
}
.section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  
}


.left img {
  max-width: 100%;
  max-height: 100%;
}


.right {
  flex-basis: 50%;
  padding: 0 20px;
}
.right h2 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 64px;
  color: #3d4dae;
}
.right p {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 37px;
  /* or 185% */

  display: flex;
  align-items: flex-end;
  letter-spacing: -0.01em;

  /* Text color 2 */

  color: #686868;
}
.right li {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 37px;
  /* or 185% */

  letter-spacing: -0.01em;

  /* Text color 2 */

  color: #686868;
}

.custom-hero-section {
  width: 100%;
  height: 55vh;
  background: url("../Assets/mainbg2.png") no-repeat center center/cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 6%;

}

.custom-hero-content {
  max-width: 800px;
  padding: 20px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

 

.slide-container {
  max-width: 1120px;
  width: 100%;
  padding: 40px 0;
}
.slide-content {
  margin: 0 40px;
 
  border-radius: 25px;
}
.card {
  border-radius: 25px;
  background-color: #fff;
}
.image-content,
.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 14px;
}
.image-content {
  position: relative;
  row-gap: 5px;
  padding: 25px 0;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #4070f4;
  border-radius: 25px 25px 0 25px;
}
.overlay::before,
.overlay::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -40px;
  height: 40px;
  width: 40px;
  background-color: #4070f4;
}
.overlay::after {
  border-radius: 0 25px 0 0;
  background-color: #fff;
}
.card-image {
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background: #fff;
  padding: 3px;
}
.card-image .card-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #4070f4;
}
.name {
  font-size: 18px;
  font-weight: 500;
  color: #333;
}
.description {
  font-size: 14px;
  color: #707070;
  text-align: center;
}
.button {
  border: none;
  font-size: 16px;
  color: #fff;
  padding: 8px 16px;
  background-color: #4070f4;
  border-radius: 6px;
  margin: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button:hover {
  background: #265df2;
}

.custom-hero-content h3 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  line-height: 62px;
  text-align: center;
  font-weight: bolder;
  color: #ffffff;
}

.custom-hero-content p {
  margin-bottom: 20px;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #cbd5e1;
  text-align: center;
  margin-top: 4%;
}
.end_pro
{
  /* background-color: #000000; */
  height: 100vh;
  width: 100%;
  margin-top: -95px;
  margin-bottom: -80px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.end_pro_left
{
  height: 100%;
  width: 50%;
}
.end_pro_right
{
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right_pro_end
{
  width: 70%;
  
}
.custom-hero-content .custom-button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin-top: 3%;
  color: #040108;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
/* CSS for center alignment */
.container-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.container-icon h2 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 64px;

  /* PURPLE */

  color: #3d4dae;
}
.container-icon p {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  /* gray/600 */

  color: #52525b;
}
/* CSS for icons */
.icon-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.icon {
  margin: 10px;
}


@media screen and (max-width: 420px) {
  .processSteps {
    flex-direction: column;
  }
  .mainsection
  {
    display: none;
  }
  .bannner-image {
    width: 100%;
    max-width: 100%;
    height: 90vh;
    display: flex;
    color: white;
    padding-left: 5%;
    background: radial-gradient(20.81% 56.75% at 68.18% 49.93%, rgba(34, 57, 197, 0.8) 0%, rgba(10, 27, 128, 0.8) 100%) ;
    padding-top: 2%;
  }
  .btn_pro
  {
    background-color: #040108;
    border: none;
    outline: none;
    background-color: white;
    width: 160px;
    height: 40px;
    border-radius: 5px;
    color: #015364;
    font-size: 14px;
    margin-top: 70px;
  }
  .monile_mar
  {
    margin-top: 15% !important;
  }

 
.formheight
{
  background-color: #000000;
  height: 50vh;
}
.conact-c1 {
  background-image:url('../Assets/contactbg.png') ;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
}
.align_contact_lab
{
  align-self: flex-start;
  display: flex;
  
}
.input_pro_eng
{
  background-color: transparent;
  outline: none;
  border: 1px solid #CBD5E1 ;
  display: block;
  height: 40px;
  border-radius: 5px;
  width: 100%;
 padding-left: 10px;
 margin-top: -5px;
 font-size: 12px;
 }
 .input_pro_eng_inp
{
  background-color: transparent;
  outline: none;
  border: 1px solid #CBD5E1 ;
  display: block;
  height: 90px;
  border-radius: 5px;
  width: 100%;
 padding-left: 10px;
 margin-top: -5px;
 font-size: 12px;
 }
 .container-icon h2 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 64px;

  /* PURPLE */

  color: #3d4dae;
}
.heading {
  color: #3d4dae;
  font-size: 30px !important;
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
}
.border_right
{
  border-bottom:1px solid  gray ;
}
.card_pro_eng_botm
{
   height: 170vh;
   justify-content: space-between;
   display: flex;
   flex-direction: column;
   margin-top:50px ;
  

}
.sub_card_pro_eng_botm
{
  height: 18%;
  width: 90%;
  background-color: white;
  border-radius: 4px;
  /* background-color: #000000; */
  margin-left: 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
.your_patner_pro
{
  background-color: #4050AF;
  height: 40vh;
  width: 100%;
  margin-top: 100px;
  text-align: center;
  color: white;
  padding: 3% 10%;
   margin-bottom: 200vh;
}
.bg_baneer_proemg
{
  display: none;
}
.end_pro
{

  display: none;
  
}
.custom-hero-section {
  width: 100%;
  height: 80vh;
  background: url("../Assets/mainbg2.png") no-repeat center center/cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 5%;

}
.conact-c1 {
  background-image:url('../Assets/contactbg.png') ;
  background-repeat: no-repeat;
  background-size: cover;
width: 99%;
  padding-top: 0%;
  padding-left: 0%;
  height: auto;
 
}
.contact-head {

  margin-top: 0px;
  font-size: 30px;
  text-align: left;
  font-weight: bolder;
  font-family: "Segoe UI";
  /* or 110% *
  /* base/white */
  color: #ffffff;
  letter-spacing: 0.5px;
  margin-left: 10%;
  margin-top: 10%;
}
.contact-para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  margin-left: 10%;
  margin-top: 2%;

  color: #cbd5e1;
}
}
